import React from 'react';
import BasePage from '@/components/BasePage';
import { translate } from 'react-switch-lang';

const Footer = ({ t }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Page - Shared component with Container */}
			<BasePage id="footer-page" className="footer-page" role="article">
				<footer>
					<p itemScope itemType="https://schema.org/CreativeWork">
						&copy; <span itemProp="copyrightYear">{new Date().getFullYear()}</span> -&nbsp;
						{t('footer.copyrights', { name: '' })}&nbsp;
						<a
							className="page_subtext inline"
							title={t('footer.copyrights', { name: 'Artiom Tofan' })}
							rel="external nofollow noopener"
							itemProp="copyrightHolder"
							target="_blank"
							href="https://www.linkedin.com/in/artiom-tofan/"
						>
							Artiom Tofan
						</a>
					</p>
				</footer>
			</BasePage>
		</React.Fragment>
	);
};
export default translate(Footer);
