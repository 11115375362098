import React, { Component } from 'react';
import { hotjar } from 'react-hotjar';

class BookingScript extends Component {
	componentDidMount() {
		window.BOOKING_PAGE_LINK = process.env.NEXT_PUBLIC_BOOKING_PAGE_LINK;
		// window.BOOKING_PAGE_LINK = process.env.NEXT_PUBLIC_BOOKING_PAGE_LINK_BETA;
		// window.BOOKING_FILE_PATH = process.env.NEXT_PUBLIC_BOOKING_FILE_PATH_BETA;
		window.BOOKING_FILE_PATH = process.env.NEXT_PUBLIC_BOOKING_FILE_PATH;
		window.LOADING_BOOKING = process.env.NEXT_PUBLIC_LOADING_BOOKING;
		const script = document.createElement('script');
		script.async = true;
		script.type = 'text/javascript';
		script.src = '../../../JS/bookingScript.js'; 
		this.div.appendChild(script);
		hotjar.initialize(process.env.HOTJAR_ID, 6);
	}
	render() {
		return (
			<div className="App" ref={(el) => (this.div = el)}>
				<a className="open_booking-iframe-navbutton" id="open_booking-iframe-image">
					<img
						width="36"
						height="157"
						border="none"
						src="https://my.setmore.com/webapp/images/bookappt/Setmore-Book-Now.png"
						alt="Book an appointment with Emilia Scanning Clinic"
					/>
				</a>
			</div>
		);
	}
}

export default BookingScript;
