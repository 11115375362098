import React from 'react';
import BasePage from '@/components/BasePage';
import Link from 'next/link';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate, getLanguage } from 'react-switch-lang';

const TopInfo = ({ t }) => {
  return (
    //React.Fragment is used instead of <div> or <> to hide it from source
    <React.Fragment>
      {/* Base Page - Shared component with Container */}
      <BasePage id='topinfo-page' className='topinfo-page' role='banner'>
        <header className='pt-3'>
          <Row itemScope itemType='https://schema.org/LocalBusiness'>
            {/* Company Logo */}
            <Col xs='2' md='1' itemScope itemType='http://schema.org/Attorney'>
              <Link href='/'>
                <a itemProp='url' title={t('topinfo.logo')}>
                  <img
                    itemProp='logo'
                    className='company_logo'
                    src='/icons/logo.webp'
                    alt={t('topinfo.logo')}
                    title={t('topinfo.logo')}
                  />
                </a>
              </Link>
            </Col>
            {/* Company Header and information */}
            <Col
              xs='8'
              md='3'
              title={t('topinfo.logo')}
              className='topinfo_logo-header-company text-uppercase'
            >
              <Link href='/'>
                <a itemProp='url' title={t('topinfo.name')}>
                  <p itemProp='name'>
                    <span className='font-weight-bold colored title'>
                      {t('topinfo.name')}
                    </span>
                    <span className='d-block' itemProp='description'>
                      {t('topinfo.company')}
                    </span>
                  </p>
                </a>
              </Link>
            </Col>
            {/* Company Location */}
            <Col
              md='5'
              lg='4'
              className='topinfo_logo-header-location d-none d-md-inline text-uppercase'
            >
              <a
                title={`${t('topinfo.location')}: ${
                  getLanguage() == 'sv'
                    ? 'Fågelbacksgatan 9c, lokal 2110, 217 45  Malmö'
                    : 'Amager Landevej 31, 2770 Kastrup'
                }`}
                rel='external nofollow noopener'
                target='_blank'
                href={
                  getLanguage() == 'sv'
                    ? 'https://www.google.com/maps/place/F%C3%A5gelbacksgatan+9c,+217+45+Malm%C3%B6,+Sweden/@55.5972944,12.9832834,17z/data=!3m1!4b1!4m6!3m5!1s0x4653a153368eaaab:0xd7b48c2f1e737f7a!8m2!3d55.5972914!4d12.9858583!16s%2Fg%2F11h8jhzmhg?entry=ttu'
                    : 'https://www.google.com/maps/place/Amager+Landevej+31,+2770+Kastrup/@55.6375594,12.6167232,17z/data=!3m1!4b1!4m5!3m4!1s0x4653ab59f87606ef:0x29e0296915c20f9a!8m2!3d55.6375594!4d12.6189119'
                }
              >
                <FontAwesomeIcon
                  icon={['fas', 'map-marker-alt']}
                  className='fai_map-marker'
                />
                {t('topinfo.location')}
                <span className='title d-block'>
                  <span itemProp='streetAddress'>
                    {getLanguage() == 'sv'
                      ? 'Fågelbacksgatan 9c, lokal 2110'
                      : 'Amager Landevej 31'}
                  </span>
                  ,
                  <span itemProp='postalCode'>
                    {getLanguage() == 'sv' ? ' 217 45  Malmö' : ' 2770 Kastrup'}
                  </span>
                </span>
              </a>
            </Col>
            {/* Company opening hours */}
            <Col
              md='1'
              lg='2'
              title={t('topinfo.hours')}
              className='topinfo_logo-header-time d-none d-lg-inline text-uppercase'
            >
              <FontAwesomeIcon icon={['fas', 'clock']} className='fai_clock' />
              <p>
                {t('topinfo.open')}
                <span className='title d-block'>
                  <time
                    itemProp='openingHours'
                    dateTime='Mon to Fri 08:00-16:00'
                  >
                    08.00 - 16.00
                  </time>
                </span>
              </p>
            </Col>
            {/* Company phone number */}
            <Col
              md='3'
              lg='2'
              className='topinfo_logo-header-phone d-none d-md-inline text-uppercase'
            >
              <FontAwesomeIcon icon={['fas', 'phone']} className='fai_phone' />
              <a
                title={`${t('topinfo.telephone')} ${getLanguage() == 'sv' ? '+46764194232' : '+4532420330'}`}
                href={`${getLanguage() == 'sv' ? 'tel:+46764194232' : 'tel:+4532420330'}`}
              >
                <span>{t('topinfo.call')}</span>
                <span className='title d-block' itemProp='telephone'>
                { getLanguage() == 'sv' ? '(+46) 764194232' : '(+45) 32420330'}    
                </span>
              </a>
            </Col>
          </Row>
        </header>
      </BasePage>
    </React.Fragment>
  );
};
export default translate(TopInfo);
