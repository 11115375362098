import React, { useState, useRef, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import BasicNavLink from '@/Handlers/BasicNavLink';
import BookingScript from '@/utils/booking';
import BookingButton from '@/components/BookingButton';
import { startLanguageLoadingTimer } from '@/Handlers/HandleLanguageLoading';
import rootChecker from '@/Hooks/rootChecker';
// Translation Higher Order Component
import { translate, getLanguage } from 'react-switch-lang';
import { useRouter } from 'next/router';
// Basic Link Layout, that defines a route, and a title, and returns a JSX element of a Link with a Title
const ScrollNavLink = ({ route, title, click, offset }) => {
	return (
		//Scroll Link options, toggle class, and links classes
		<ScrollLink
			href={`#${route}`}
			className="nav-link port-navbar-link clickable line-height"
			activeClass="active"
			to={route}
			spy={true}
			smooth={true}
			duration={600}
			offset={offset}
			isDynamic={true}
			onClick={click}
			ignoreCancelEvents={true}
		>
			{title}
		</ScrollLink>
	);
};

// Base NavBar - which will be a shared component -  which has user data and loading state
const NavBar = ({ t }) => {
	const router = useRouter();

	// Default Reactstrap toggle function which sets true or false depending on the onClick event
	const [ isOpen, setIsOpen ] = useState(false);
	// Defining state for fixing the navbar
	const [ navFixedTop, setNavFixedTop ] = useState(false);

	// Blank Function
	const doNothing = () => {
		return;
	};

	// Toggle hamburger menu if the screen is less than 1200 pixels
	const toggleMenu = () => {
		if (window.innerWidth < 1200) {
			setIsOpen(!isOpen);
		}
	};

	const navRef = useRef();
	navRef.current = navFixedTop;
	// Checking if our screen scroll is more than 200Y
	useEffect(() => {
		// Checking if our width is less than 992
		const mq = window.matchMedia('(max-width: 992px)');
		let stick;
		const handleScroll = () => {
			// If the media query width is less than 992 then we set stick to 23
			if (mq.matches) {
				stick = window.scrollY > 0;
				// Else If the media query width is more than 992 then we set stick to 65
			} else {
				stick = window.scrollY > 65;
			}

			if (navRef.current !== stick) {
				setNavFixedTop(stick);
			}
		};
		// Make an initial check if the content is loaded, to place the navbar
		if (window.scrollY > 65) {
			handleScroll();
		}
		// Check if we are scrolling then place the navbar
		document.addEventListener('scroll', handleScroll, { passive: true });
		return () => {
			document.removeEventListener('scroll', handleScroll, { passive: true });
		};
	}, []);

	// Setting the language
	const handleLanguage = (key) => () => {
		startLanguageLoadingTimer(key);
	};

	// Language icons logic
	const LanguagesSelection = ({ lang }) => {
		useEffect(
			() => {
				document.documentElement.lang = getLanguage();
			},
			[ getLanguage() ]
		);
		return (
			<img
				onClick={handleLanguage(lang)}
				className="navbar-flags"
				src={`/icons/${lang}-flag-xs.webp`}
				alt={t(`SEO.alt_${lang}`)}
				title={t(`SEO.alt_${lang}`)}
				width="30"
				height="20"
			/>
		);
	};

	return (
		// React.Fragment is used instead of <div> or <> to hide it from source


			rootChecker() ? <React.Fragment>
				{/* Booking Widget */}
				<BookingScript />
				{/* Simple Collapsable Bootstrap Navbar which is set to expand on medium screens */}
				<Navbar
					className={`port-navbar port-default ${
						navFixedTop ? 'nav-fixed nav_fixed-top navbar-color_transparency-half' :
						'nav_not-fixed navbar-color_transparency-full'}`}
					expand="lg"
				>
					{/* Toggler for the Navbar when it shows on the medium screen */}
					<NavbarToggler onClick={toggleMenu}>
						{/* Close mark */}
						<div
							id="close-icon"
							className={`${
								isOpen ? 'open' :
								''}`}
						>
							<span />
							<span />
							<span />
						</div>
					</NavbarToggler>
					{/* close mark ends */}
					<Collapse isOpen={isOpen} navbar className="float-right text-right">
						{/* All the content to the Left */}
						<Nav className="mr-auto" navbar>
							{/* Navlinks we defined in this component */}
							<NavItem className="port-navbar-item">
								<ScrollNavLink
									click={

											isOpen ? toggleMenu :
											doNothing
									}
									route="topinfo-page"
									title={t('navbar.home')}
								/>
							</NavItem>
							{/* <NavItem className="port-navbar-item">
								<ScrollNavLink
									click={

											isOpen ? toggleMenu :
											doNothing
									}
									route="services-page"
									offset={0}
									title={t('navbar.services')}
								/>
							</NavItem> */}
							<NavItem className="port-navbar-item">
								<BasicNavLink height="line-height" route="/about" title={t('navbar.about')} />
							</NavItem>
							<NavItem className="port-navbar-item">
								<BasicNavLink height="line-height" route="/prices" title={t('navbar.prices')} />
							</NavItem>
							<NavItem className="port-navbar-item">
								<BasicNavLink route="/pregnancy" title={t('navbar.pregnancy')} height="line-height" />
							</NavItem>
							<NavItem className="port-navbar-item">
								<ScrollNavLink
									click={

											isOpen ? toggleMenu :
											doNothing
									}
									route="contacts-page"
									title={t('navbar.contacts')}
								/>
							</NavItem>
						</Nav>
						<Nav className="ml-auto booking_button_text" navbar>
							<NavItem className="port-navbar-item">
								<BookingButton
									booking={t('navbar.booking')}
									onClick={

											isOpen ? toggleMenu :
											doNothing
									}
								/>
							</NavItem>
						</Nav>
						<div className="navbar-flags_container d-sm-inline">
							<LanguagesSelection
								lang={

										getLanguage() == 'da' ? 'en' :
										'da'
								}
							/>
							<LanguagesSelection
								lang={

										getLanguage() == 'sv' ? 'en' :
										'sv'
								}
							/>
						</div>
					</Collapse>
				</Navbar>
			</React.Fragment> :
			<React.Fragment>
				{/* Booking Widget */}
				<BookingScript />
				{/* Simple Collapsable Bootstrap Navbar which is set to expand on medium screens  */}
				<Navbar
					className={`port-navbar port-default ${
						navFixedTop ? 'nav-fixed nav_fixed-top navbar-color_transparency-half' :
						'nav_not-fixed navbar-color_transparency-full'}`}
					expand="lg"
				>
					{/* Toggler for the Navbar when it shows on the medium screen */}
					<NavbarToggler onClick={toggleMenu}>
						{/* Close mark */}
						<div
							id="close-icon"
							className={`${
								isOpen ? 'open' :
								''}`}
						>
							<span />
							<span />
							<span />
						</div>
					</NavbarToggler>
					{/* close mark ends */}
					<Collapse isOpen={isOpen} navbar className="float-right text-right">
						{/* All the content to the Left */}
						<Nav className="mr-auto" navbar>
							<NavItem className="port-navbar-item">
								<BasicNavLink route="/" title={t('navbar.home')} />
							</NavItem>
							<NavItem className="port-navbar-item">
								{/* Navlinks we defined in this component */}
								<a className="nav-link port-navbar-link clickable" onClick={() => router.back()}>
									{t('navbar.back')}
								</a>
							</NavItem>
							<NavItem className="no-hover nav-link port-navbar-link">-</NavItem>
							<NavItem className="port-navbar-item">
								<BasicNavLink height="line-height" route="/about" title={t('navbar.about')} />
							</NavItem>
							<NavItem className="port-navbar-item">
								<BasicNavLink height="line-height" route="/prices" title={t('navbar.prices')} />
							</NavItem>
							<NavItem className="port-navbar-item">
								<BasicNavLink route="/pregnancy" title={t('navbar.pregnancy')} />
							</NavItem>
							<NavItem className="port-navbar-item">
								<ScrollNavLink
									click={

											isOpen ? toggleMenu :
											doNothing
									}
									route="contacts-page"
									title={t('navbar.contacts')}
								/>
							</NavItem>
						</Nav>
						<Nav className="ml-auto booking_button_text" navbar>
							<NavItem className="port-navbar-item">
								<BookingButton
									booking={t('navbar.booking')}
									onClick={

											isOpen ? toggleMenu :
											doNothing
									}
								/>
							</NavItem>
						</Nav>
						<div className="navbar-flags_container d-sm-inline">
							<LanguagesSelection
								lang={

										getLanguage() == 'da' ? 'en' :
										'da'
								}
							/>
							<LanguagesSelection
								lang={

										getLanguage() == 'sv' ? 'en' :
										'sv'
								}
							/>
						</div>
					</Collapse>
				</Navbar>
			</React.Fragment>
	);
};

export default translate(NavBar);
