import React from 'react';
import Head from 'next/head';
import TopInfo from '@/components/shared/TopInfo';
import NavBar from '@/components/shared/NavBar';
import Contacts from '@/pages/contacts';
import Footer from '@/components/shared/Footer';
import { setTranslations, setDefaultLanguage } from 'react-switch-lang';
import HandleLanguageLoading from '@/Handlers/HandleLanguageLoading';
import en from '@/languages/en.json';
import da from '@/languages/da.json';
import sv from '@/languages/sv.json';
// Do this two lines only when setting up the application
setTranslations({ en, da, sv });
setDefaultLanguage('da');
//Retrieving user, loading, className, and children from porps
const BaseLayout = ({ className, children, title, description, keywords, canonical }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Meta Tags */}
			<main className={`${className}`}>
				<Head>
					{/* SEO Tags */}
					<title>{`Emilia Scanning - ${title}`}</title>
					<meta name="description" content={description} />
					<meta name="keywords" content={keywords} />
					{/* OG Tags */}
					<meta property="og:locale" content="da_DK" />
					<meta property="og:locale:alternate" content="en_DK" />
					<meta property="og:locale:alternate" content="sv_DK" />
					<meta property="og:title" content={`Emilia Scanning - ${title}`} />
					<meta property="og:type" content="website" />
					<meta property="og:description" content={description} />
					<meta property="og:image" content="https://www.emiliascanning.com/images/bellyScan.webp" />
					<meta property="og:url" content="https://www.emiliascanning.com/" />
					<meta property="og:site_name" content="Emilia Scanning" />
					{/* Twitter Tags */}
					<meta name="twitter:card" content="summary" />
					<meta property="twitter:url" content="https://www.emiliascanning.com/" />
					<meta property="twitter:title" content={`Emilia Scanning - ${title}`} />
					<meta property="twitter:description" content={description} />
					<meta property="twitter:image" content="https://www.emiliascanning.com/images/bellyScan.webp" />
					{/* ViewPort Tags */}
					<meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1" />
					<meta name="HandheldFriendly" content="true" />
					<link rel="alternate" href="https://www.emiliascanning.com/da" hrefLang="da" />
					<link rel="alternate" href="https://www.emiliascanning.com/en" hrefLang="en" />
					<link rel="alternate" href="https://www.emiliascanning.com/sv" hrefLang="sv" />
					{canonical && <link rel="canonical" href={`https://www.emiliascanning.com${canonical}`} />}
					<link rel="home" href="https://www.emiliascanning.com/" />
					<meta name="robots" content="index,follow" />
					<meta name="parsely-link" content="https://www.emiliascanning.com/" />
					<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				</Head>
				{/* Base NavBar - Shared component */}
				<HandleLanguageLoading />
				<TopInfo />
				<NavBar />
				<section className="wrapper">{children}</section>
				<Contacts />
				<Footer />
			</main>
		</React.Fragment>
	);
};

export default BaseLayout;
