import React, { useState } from 'react';
import { Input, Form, FormGroup, Button, Container } from 'reactstrap';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const ContactForm = ({ t }) => {
	const [ status, setStatus ] = useState({
		submitted: false,
		submitting: false,
		info: { error: false, msg: null }
	});

	const [ inputs, setInputs ] = useState({
		email: '',
		name: '',
		telephone: '',
		message: ''
	});

	const handleResponse = (status, msg) => {
		if (status === 200) {
			setStatus({
				submitted: true,
				submitting: false,
				info: { error: false, msg: msg }
			});
			setInputs({
				email: '',
				name: '',
				telephone: '',
				message: ''
			});
		} else {
			setStatus({
				info: { error: true, msg: msg }
			});
		}
	};

	const handleOnChange = (e) => {
		e.persist();
		setInputs((prev) => ({
			...prev,
			[e.target.id]: e.target.value
		}));
		setStatus({
			submitted: false,
			submitting: false,
			info: { error: false, msg: null }
		});
	};

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
		const res = await fetch('/api/send', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(inputs)
		});
		const text = await res.text();
		handleResponse(res.status, text);
	};

	return (
		<Container>
			<Form onSubmit={handleOnSubmit}>
				<FormGroup>
					<label className="contacts-page_text" htmlFor="email">
						Email
					</label>
					<span className="red">*</span>
					<Input
						autoComplete="new-password"
						id="email"
						type="email"
						placeholder="name@example.com"
						onChange={handleOnChange}
						required
						value={inputs.email}
					/>
					<div className="float-sm-left float-md-none float-xl-left">
						<label className="contacts-page_text" htmlFor="name">
							{t('contacts.name')}
						</label>
						<span className="red">*</span>
						<Input
							autoComplete="new-password"
							id="name"
							type="text"
							placeholder="John Doe"
							onChange={handleOnChange}
							required
							value={inputs.name}
						/>
					</div>
					<div className="float-sm-right float-md-none float-xl-right">
						<label className="contacts-page_text" htmlFor="telephone">
							{t('contacts.phone')}
						</label>
						<span className="red">*</span>
						<Input
							autoComplete="new-password"
							id="telephone"
							type="tel"
							placeholder="(+45) 32420330"
							onChange={handleOnChange}
							required
							value={inputs.telephone}
						/>
					</div>
					<label className="contacts-page_text" htmlFor="message">
						{t('contacts.message')}
					</label>
					<span className="red">*</span>
					<Input
						autoComplete="new-password"
						id="message"
						onChange={handleOnChange}
						required
						value={inputs.message}
						bsSize="lg"
						placeholder={`${t('contacts.message_placeholder')}...`}
						rows="3"
						type="textarea"
					/>
					<div className="text-center">
						<Button className="contact-form_button mt-3" color="info" type="submit" disabled={status.submitting}>
							{
								!status.submitting ? !status.submitted ? t('contacts.submit') :
								t('contacts.submitted') :
								t('contacts.submitting')}
						</Button>
					</div>
				</FormGroup>
			</Form>
		</Container>
	);
};

export default translate(ContactForm);
