import useToggle from '@/Hooks/useToggle';
import Loading from '@/components/shared/Loading';
import { motion } from 'framer-motion';
// Translation Higher Order Component
import { setLanguage } from 'react-switch-lang';

let loading;

const HandleLanguageLoading = () => {
	const [ isLoading, setLoading ] = useToggle(false);
	loading = setLoading;
	return (
		<motion.div
			initial={isLoading ? 'visible' : 'hidden'}
			animate={isLoading ? 'visible' : 'hidden'}
			variants={{
				hidden: {
					display: 'none'
				},
				visible: {
					display: 'inline'
				}
			}}
		>
			<Loading type="bars" color="#52C8FA" />
		</motion.div>
	);
};

export const startLanguageLoadingTimer = (key) => {
	loading();
	setLanguage(key);		
	setTimeout(() => {
		loading();
	}, 200);
};

export default HandleLanguageLoading;
