import { Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Booking = ({ t }) => {
	return (
		<Row className="justify-content-center pb-5">
			<a
				rel="external nofollow noopener"
				target="_blank"
				className="booking_button clickable open_booking-iframe-navbutton text-uppercase shadowed-img"
			>
				<FontAwesomeIcon icon={[ 'fas', 'calendar-check' ]} />&nbsp;{t('navbar.booking')}
			</a>
		</Row>
	);
};
export default translate(Booking);
