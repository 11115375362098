import Link from 'next/link';
import { useRouter } from 'next/router';

//Basic Nav Link Layout, that defines a route, and a title, and returns a JSX element of a Link with a Title
const BasicNavLink = ({ route, title, height }) => {
	const routeChecker = (route) => {
		const router = useRouter();
		const is_route = router.pathname == route;
		return is_route;
	};

	return (
		<Link passHref href={`${route}`}>
			<a
				className={
					routeChecker(route) ? (
						`nav-link port-navbar-link active ${height || ''}`
					) : (
						`nav-link port-navbar-link ${height || ''}`
					)
				}
			>
				{title}
			</a>
		</Link>
	);
};

export default BasicNavLink;
