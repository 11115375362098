import React from 'react';
import BasePage from '@/components/BasePage';
import ContactForm from '@/components/ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Container } from 'reactstrap';

// Translation Higher Order Component
import { translate, getLanguage } from 'react-switch-lang';

const Contacts = ({ t }) => {
  return (
    //React.Fragment is used instead of <div> or <> to hide it from source
    <React.Fragment>
      {/* Base Page - Shared component with Container */}
      <BasePage id='contacts-page' className='contacts-page'>
        <Container>
          <section>
            <Row>
              <Col md='6'>
                <p className='contacts-page_text pt-4'>
                  {t('contacts.phone')}
                  <a
                    className='float-right page-links'
                    title={`${t('topinfo.telephone')} ${getLanguage() == 'sv' ? '+46764194232' : '+4532420330'}`}
                    href={`${getLanguage() == 'sv' ? 'tel:+46764194232' : 'tel:+4532420330'}`}
                  >
                    <span className='inline' itemProp='telephone'>
                      <FontAwesomeIcon icon={['fas', 'phone']} />
                      &nbsp; { getLanguage() == 'sv' ? '(+46) 764194232' : '(+45) 32420330'}              
                    </span>
                  </a>
                </p>
                <p className='contacts-page_text pb-3'>
                  {t('contacts.address')}
                  <a
                    className='float-right page-links'
                    title={`${t('topinfo.location')}: ${
                      getLanguage() == 'sv'
                        ? 'Fågelbacksgatan 9c, lokal 2110, 217 45  Malmö'
                        : 'Amager Landevej 31, 2770 Kastrup'
                    }`}
                    rel='external nofollow noopener'
                    target='_blank'
                    href={
                      getLanguage() == 'sv'
                        ? 'https://www.google.com/maps/place/F%C3%A5gelbacksgatan+9c,+217+45+Malm%C3%B6,+Sweden/@55.5972944,12.9832834,17z/data=!3m1!4b1!4m6!3m5!1s0x4653a153368eaaab:0xd7b48c2f1e737f7a!8m2!3d55.5972914!4d12.9858583!16s%2Fg%2F11h8jhzmhg?entry=ttu'
                        : 'https://www.google.com/maps/place/Amager+Landevej+31,+2770+Kastrup/@55.6375594,12.6167232,17z/data=!3m1!4b1!4m5!3m4!1s0x4653ab59f87606ef:0x29e0296915c20f9a!8m2!3d55.6375594!4d12.6189119'
                    }
                  >
                    <span className='inline'>
                      <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />{' '}
                      &nbsp;
                      <span itemProp='streetAddress'>
                        {getLanguage() == 'sv'
                          ? 'Fågelbacksgatan 9c, lokal 2110'
                          : 'Amager Landevej 31'}
                        <br></br>
                        <span className='float-right' itemProp='postalCode'>
                          {getLanguage() == 'sv'
                            ? ' 217 45  Malmö'
                            : ' 2770 Kastrup'}
                        </span>
                      </span>
                    </span>
                  </a>
                </p>
                <p className='contacts-page_text'>
                  Instagram
                  <a
                    className='float-right page-links'
                    title='Instagram Link: https://www.instagram.com'
                    rel='external nofollow noopener'
                    target='_blank'
                    href=' https://www.instagram.com'
                  >
                    <span className='inline' itemProp=''>
                      <FontAwesomeIcon icon={['fab', 'instagram']} /> &nbsp;
                      <span>emiliaklinik.com.7</span>
                    </span>
                  </a>
                </p>
                <p className='contacts-page_text'>
                  Facebook
                  <a
                    className='float-right page-links'
                    title='Facebook Link: https://www.facebook.com/emiliaklinik.com.7/'
                    rel='external nofollow noopener'
                    target='_blank'
                    href='https://www.facebook.com/emiliaklinik.com.7/'
                  >
                    <span className='inline' itemProp=''>
                      <FontAwesomeIcon icon={['fab', 'facebook-square']} />{' '}
                      &nbsp;
                      <span>emiliaklinik.com.7</span>
                    </span>
                  </a>
                </p>
                <p className='contacts-page_text'>
                  {t('contacts.write')}
                  <a
                    className='inline'
                    title='E-mail klinik@rcscanning.com'
                    rel='external nofollow noopener'
                    target='_blank'
                    href='mailto:klinik@rcscanning.com'
                  >
                    <span className='float-right page-links' itemProp=''>
                      <FontAwesomeIcon icon={['fas', 'envelope']} /> &nbsp;
                      <span>klinik@rcscanning.com </span>
                    </span>
                  </a>
                </p>
                <p className='contacts-page_text'>
                  {t('contacts.vaccines')}
                  <a
                    className='float-right page-links'
                    title='Vaccin Link: https://www.vaccin.dk'
                    rel='external nofollow noopener'
                    target='_blank'
                    href='https://www.vaccin.dk'
                  >
                    <span className='inline' itemProp=''>
                      <span>VACCIN.DK</span>
                    </span>
                  </a>
                </p>
              </Col>
              <Col md='6'>
                <ContactForm white={'contacts-page_text'} />
              </Col>
            </Row>
          </section>
        </Container>
      </BasePage>
    </React.Fragment>
  );
};
export default translate(Contacts);
