import React from 'react';

// It might be a project-level reusable hook
const useToggle = (initialState) => {
	const [ isToggled, setIsToggled ] = React.useState(initialState);

	// This value never changes so the callback is not going to be ever re-created
	const toggler = React.useCallback(() => setIsToggled((state) => !state), [ setIsToggled ]);

	return [ isToggled, toggler ];
};

export default useToggle;
